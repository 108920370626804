/* global indexedDB, Sentry, IDBKeyRange */
import * as vibes from './vibeArray'
import algoliasearch from 'algoliasearch'
import * as urlHelpers from '../urlHelpers'
import { haveGtmAndGaLoaded } from '../../analytics/AnalyticsHelpers'

function stringParameterize (str) {
  return str.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, '').replace(/\s/g, '-')
}

export function getTable (dataId) {
  const element = document.querySelector(`[data-id="content-${dataId}"]`)
  return (element !== null) ? JSON.parse(element.innerHTML) : ''
}

function setFiltersFromVibe (helper) {
  vibes.VIBES.forEach(value => {
    if (window.location.pathname === ('/' + stringParameterize(value))) {
      helper.addDisjunctiveFacetRefinement('vibe_names', value)
    }
  })
}

function setFiltersFromSellerSlug (helper) {
  if (window.location.pathname.includes('/sellers/')) {
    const path = removeTraillingSlash(window.location.pathname)
    helper.addDisjunctiveFacetRefinement('user_slug', path.substring(path.lastIndexOf('/') + 1))
  }
}

function setFiltersForSale (helper) {
  if (window.location.pathname.includes('/sale')) {
    helper.addFacetRefinement('sale', true)
  }
}

function setFiltersForNewIn (helper) {
  if (window.location.pathname.includes('/new')) {
    helper.addFacetRefinement('published_recently', true)
  }
}

function removeTraillingSlash (pathname) {
  if (pathname.slice(-1) === '/') {
    return pathname.slice(0, -1)
  } else {
    return pathname
  }
}

export function addCollapseOnClick () {
  Array.from(document.getElementsByClassName('ais-Panel-header')).forEach((element) => {
    element.addEventListener('click', () => {
      const collapsedClass = 'ais-Panel--collapsed'
      const parent = element.parentElement
      if (parent.classList.contains(collapsedClass)) {
        parent.classList.remove(collapsedClass)
      } else {
        parent.classList.add(collapsedClass)
      }
    })
  })
}

function cleanVibes (vibes) {
  return vibes.filter(vibe => !(vibe === 'Unknown' || vibe === ''))
}

export function buildFilters (filtersConfig) {
  const {
    listingId, moreFromThisSeller, userSlug, productType, subProductType, subcategory, crossCategory, vibeNames, listingIds
  } = filtersConfig

  if (listingIds) {
    const listingIdsArray = JSON.parse(listingIds)
    const objectIdsString = listingIdsArray.map((objectId) => `objectID:${objectId}`).join(' OR ')

    return `sold:false AND (${objectIdsString})`
  }

  const baseFilter = 'sold:false AND NOT objectID:' + listingId

  // TODO: Can't we just test for the existence of userSlug?
  if (moreFromThisSeller) {
    return baseFilter + ' AND user_slug:"' + userSlug + '"'
  }

  if (crossCategory) {
    const vibes = cleanVibes(vibeNames.split(','))
    if (vibes.length > 0) {
      const vibeString = vibes.map((vibe) => `vibe_names:"${vibe}"`).join(' OR ')
      return baseFilter + ` AND (category_hierarchy.lvl0:"Home Decor" OR category_hierarchy.lvl0:"Lighting") AND (${vibeString})`
    } else {
      return baseFilter + ' AND (category_hierarchy.lvl0:"Home Decor" OR category_hierarchy.lvl0:"Lighting")'
    }
  }

  if (subProductType) {
    return baseFilter + ' AND sub_product_type_name:"' + subProductType + '"'
  }

  if (productType) {
    return baseFilter + ' AND product_type_name:"' + productType + '"'
  } else {
    return baseFilter + ' AND subcategory_name:"' + subcategory + '"'
  }
}

export function initiateSearch (filters = 'sold:false', explicitIndex = null) {
  const index = getSearchIndex(explicitIndex)
  const hardCodedHitsPerPage = 20
  const isTracked = isClickAnalyticsEnabled()
  return index.search('', {
    hitsPerPage: hardCodedHitsPerPage,
    page: 0,
    filters: filters,
    clickAnalytics: isTracked,
    optionalFilters: getOptionalFilters(),
    analytics: isTracked,
    attributesToRetrieve: [
      '*',
      '-description'
    ]
  })
}

export function initiateSimilarSearch (similarQuery, hitsPerPage, filters) {
  const index = getSearchIndex()
  const isTracked = isClickAnalyticsEnabled()

  return index.search('', {
    hitsPerPage: hitsPerPage,
    similarQuery: similarQuery,
    filters: filters,
    clickAnalytics: isTracked,
    attributesToRetrieve: [
      '*',
      '-description'
    ]
  })
}

export function getSearchIndex (index = null) {
  const client = algoliasearch(process.env.ALGOLIASEARCH_APPLICATION_ID, process.env.ALGOLIASEARCH_API_KEY_SEARCH, { protocol: 'https:' })
  if (index) {
    return client.initIndex(index)
  } else {
    return client.initIndex(getAlgoliaIndexFromUrl())
  }
}

export function isCollectionPage () {
  const collectionsUrlRegex = /collections\/.*$/
  return collectionsUrlRegex.test(window.location.pathname)
}

export function setCollectionsQueryFromUrl (helper) {
  if (isCollectionPage()) {
    const splitPath = window.location.pathname.split('/')
    const indexOfCollectionQuery = splitPath.indexOf('collections') + 1
    const collection = splitPath[indexOfCollectionQuery].replace(/[_-]/g, ' ')
    const shouldRestrict = getTable('restrict-collection-title')
    if (shouldRestrict) {
      helper.setQueryParameter('restrictSearchableAttributes', ['title'])
    }
    helper.setQuery(collection)
  }
}

// Likely a combination of these comments will be used soon
// here or on filters(): like we do for showcases, depends on how complex our AND /OR logic is
export function setRoomTypeQueryFromUrl (helper) {
  if (urlHelpers.isRoomTypePage()) {
    const splitPath = window.location.pathname.split('/')
    const indexOfRoomTypeQuery = splitPath.indexOf('room-type') + 1
    const roomType = splitPath[indexOfRoomTypeQuery]

    // These work like filters, conjunctive
    // args.helper.addTag("jan-sale-carryover")
    // args.helper.addDisjunctiveFacetRefinement('_tags', "jan-sale-carryover")
    // Some other price custom field

    const roomTypeCategories = getTable('room-type-categories')[roomType]
    if (roomTypeCategories) {
      roomTypeCategories.forEach(category => {
        helper.addDisjunctiveFacetRefinement('most_specific_category_name', category)
      })
    }
  }
}

export function getOptionalFilters () {
  const optionalFilters = getTable('perso-optional-filters')
  return optionalFilters
}

export function getTemplate (templateName) {
  return document.getElementById(templateName + '-template').innerHTML
}

export function getAlgoliaIndexFromUrl () {
  const nodeEnv = process.env.NODE_ENV

  if (urlHelpers.inExperiment('vSearchAlgoVar')) {
    return `Listing_${nodeEnv}_${urlHelpers.getExperimentVariant('vSearchAlgoVar')}`
  } else {
    return `Listing_${nodeEnv}`
  }
}

export function getIndicesForSortBySelector (indexName) {
  const priceType = isTradeUser() ? '_trade' : ''
  return [{
    value: indexName, label: 'Most relevant'
  }, {
    value: indexName + `${priceType}_price_asc`, label: 'Lowest price'
  }, {
    value: indexName + '_sale_percentage_desc', label: 'Highest % off'
  }, {
    value: indexName + `${priceType}_price_desc`, label: 'Highest price'
  }, {
    value: indexName + '_published_at_desc', label: 'Newest first'
  }]
}

function isTradeUser () {
  return document.querySelector('[data-trade-buyer]')?.dataset?.tradeBuyer === 'true'
}

export function getPriceAttributeName () {
  return `display_price${isTradeUser() ? '_trade_user' : ''}_for_algolia`
}

export function setFiltersFromUrl (helper) {
  setFiltersFromVibe(helper)
  setFiltersFromSellerSlug(helper)
  setFiltersForSale(helper)
  setFiltersForNewIn(helper)
}

export function pushAlgoliaAllocationPDP (indexUsed) {
  if (!haveGtmAndGaLoaded()) return
  if (mainIndex(indexUsed)) {
    const expVersion = indexUsed === 'Listing_production' ? 'control' : 'variant'
    window.dataLayer.push({
      event: 'ce - amplitude - setAlgoliaAllocations',
      user: {
        enabled_flipper_features: {
          algolia_main_ab: expVersion
        }
      }
    })
  }
}

// This method of sending allocation to Amplitude is being deprecated
// See user.algolia_allocations
export function setPLPUsedIndex (renderOpts) {
  if (renderOpts.results._rawResults[0] !== undefined) {
    const { indexUsed, abTestVariantID } = renderOpts.results._rawResults[0]
    const hitsContainer = document.getElementById('hits')
    hitsContainer.setAttribute('data-index-used', indexUsed)

    if (!haveGtmAndGaLoaded()) return
    window.dataLayer.push({
      page: {
        search_index_used: indexUsed
      }
    })

    if (abTestVariantID) {
      const expVersion = abTestVariantID === 1 ? 'control' : 'variant'
      window.dataLayer.push({
        event: 'ce - amplitude - setAlgoliaAllocations',
        user: {
          enabled_flipper_features: {
            algolia_generic_ab: expVersion
          }
        }
      })
    }

    if (mainIndex(indexUsed)) {
      const expVersion = indexUsed === 'Listing_production' ? 'control' : 'variant'
      window.dataLayer.push({
        event: 'ce - amplitude - setAlgoliaAllocations',
        user: {
          enabled_flipper_features: {
            algolia_main_ab: expVersion
          }
        }
      })
    }
  }
}

function mainIndex (indexUsed) {
  return indexUsed === 'Listing_production' || indexUsed === 'Listing_production_var_1'
}

export function showReloadFiltersDesktopMessage () {
  const reloadMessageElement = document.querySelector('.vjs-search__filters--reload-needed-desktop')
  if (!reloadMessageElement) return
  reloadMessageElement.innerHTML = 'Please reload the page to load the filters'
}

export function showReloadFiltersMobileMessage () {
  const reloadMessageElement = document.querySelector('.vjs-search__filters--reload-needed-mobile')
  if (!reloadMessageElement) return
  reloadMessageElement.innerHTML = 'Please reload the page to load the filters'
}

export function isBot () {
  const botOrRendertron = document.querySelector('.vjs-bot-or-rendertron')
  return JSON.parse(botOrRendertron?.value)
}

export function isClickAnalyticsEnabled () {
  return !isBot()
}

function openDB () {
  return new Promise((resolve, reject) => {
    if (!('indexedDB' in window)) {
      reject(new Error('IndexedDB is not supported by this browser.'))
      return
    }

    const request = indexedDB.open('AlgoliaAnalyticsDB', 3)

    request.onerror = function (event) {
      reject(new Error('IndexedDB error: ' + event.target.errorCode))
    }

    request.onsuccess = function (event) {
      const db = event.target.result

      // Perform cleanup of old records on database open
      const transaction = db.transaction(['queryids', 'queryindexes', 'searchorigins'], 'readwrite')
      const stores = ['queryids', 'queryindexes', 'searchorigins']

      stores.forEach((storeName) => {
        const store = transaction.objectStore(storeName)
        if (store.indexNames.contains('timestamp')) {
          const index = store.index('timestamp')
          // Double check, but think the attribution window for a queryID in Algolia is 7 days
          const twoWeeksAgo = Date.now() - 14 * 24 * 60 * 60 * 1000 // 2 weeks in milliseconds
          const range = IDBKeyRange.upperBound(twoWeeksAgo)

          index.openCursor(range).onsuccess = function (event) {
            const cursor = event.target.result
            if (cursor) {
              const record = cursor.value
              if (record.timestamp) {
                store.delete(cursor.primaryKey)
              }
              cursor.continue()
            }
          }
        }
      })

      resolve(db)
    }

    request.onupgradeneeded = function (event) {
      const db = event.target.result

      const createStore = (storeName) => {
        if (!db.objectStoreNames.contains(storeName)) {
          const store = db.createObjectStore(storeName, { keyPath: 'listingID' })
          store.createIndex('timestamp', 'timestamp', { unique: false })
        } else {
          const transaction = event.currentTarget.transaction
          const store = transaction.objectStore(storeName)
          if (!store.indexNames.contains('timestamp')) {
            store.createIndex('timestamp', 'timestamp', { unique: false })
          }
        }
      }

      createStore('queryids')
      createStore('queryindexes')
      createStore('searchorigins')
    }
  })
}

export async function setQueryID (listingID, queryID) {
  try {
    const db = await openDB()
    const transaction = db.transaction(['queryids'], 'readwrite')
    const store = transaction.objectStore('queryids')
    const timestamp = Date.now()
    store.put({ listingID, queryID, timestamp })
  } catch (error) {
    console.error('Falling back to localStorage due to:', error)
    Sentry.captureException(error)
    if (window.localStorage) {
      window.localStorage.setItem(`algoliaQueryID-${listingID}`, queryID)
    }
  }
}

export async function getQueryID (listingID) {
  try {
    const db = await openDB()
    const transaction = db.transaction(['queryids'], 'readonly')
    const store = transaction.objectStore('queryids')
    const request = store.get(listingID)

    return new Promise((resolve, reject) => {
      request.onsuccess = function (event) {
        if (event.target.result) {
          resolve(event.target.result.queryID)
        } else {
          if (window.localStorage) {
            resolve(window.localStorage.getItem(`algoliaQueryID-${listingID}`))
          } else {
            resolve(null)
          }
        }
      }
      request.onerror = function (event) {
        reject(new Error('IndexedDB read error: ' + event.target.errorCode))
      }
    })
  } catch (error) {
    console.error('Error accessing IndexedDB:', error)
    Sentry.captureException(error)
    if (window.localStorage) {
      return window.localStorage.getItem(`algoliaQueryID-${listingID}`)
    }
    return null
  }
}

export async function setQueryIndex (listingID, indexUsed) {
  try {
    const db = await openDB()
    const transaction = db.transaction(['queryindexes'], 'readwrite')
    const store = transaction.objectStore('queryindexes')
    const timestamp = Date.now()
    store.put({ listingID, indexUsed, timestamp })
  } catch (error) {
    console.error('Falling back to localStorage due to:', error)
    Sentry.captureException(error)
    if (window.localStorage) {
      window.localStorage.setItem(`algoliaQueryIndex-${listingID}`, indexUsed)
    }
  }
}

export async function getQueryIndex (listingID) {
  try {
    const db = await openDB()
    const transaction = db.transaction(['queryindexes'], 'readonly')
    const store = transaction.objectStore('queryindexes')
    const request = store.get(listingID)

    return new Promise((resolve, reject) => {
      request.onsuccess = function (event) {
        if (event.target.result) {
          resolve(event.target.result.indexUsed)
        } else {
          if (window.localStorage) {
            resolve(window.localStorage.getItem(`algoliaQueryIndex-${listingID}`))
          } else {
            resolve('Listing_production')
          }
        }
      }
      request.onerror = function (event) {
        reject(new Error('IndexedDB read error: ' + event.target.errorCode))
      }
    })
  } catch (error) {
    console.error('Error accessing IndexedDB:', error)
    Sentry.captureException(error)
    if (window.localStorage) {
      return window.localStorage.getItem(`algoliaQueryIndex-${listingID}`)
    }
    return 'Listing_production'
  }
}

export async function setSearchOrigin (listingID, searchOrigin) {
  try {
    const db = await openDB()
    const transaction = db.transaction(['searchorigins'], 'readwrite')
    const store = transaction.objectStore('searchorigins')
    const timestamp = Date.now()
    store.put({ listingID, searchOrigin, timestamp })
  } catch (error) {
    console.error('Falling back to localStorage due to:', error)
    Sentry.captureException(error)
    if (window.localStorage) {
      window.localStorage.setItem(`algoliaSearchOrigin-${listingID}`, searchOrigin)
    }
  }
}

export async function getSearchOrigin (listingID) {
  try {
    const db = await openDB()
    const transaction = db.transaction(['searchorigins'], 'readonly')
    const store = transaction.objectStore('searchorigins')
    const request = store.get(listingID)

    return new Promise((resolve, reject) => {
      request.onsuccess = function (event) {
        if (event.target.result) {
          resolve(event.target.result.searchOrigin)
        } else {
          if (window.localStorage) {
            resolve(window.localStorage.getItem(`algoliaSearchOrigin-${listingID}`))
          } else {
            resolve(null)
          }
        }
      }
      request.onerror = function (event) {
        reject(new Error('IndexedDB read error: ' + event.target.errorCode))
      }
    })
  } catch (error) {
    console.error('Error accessing IndexedDB:', error)
    Sentry.captureException(error)
    if (window.localStorage) {
      return window.localStorage.getItem(`algoliaSearchOrigin-${listingID}`)
    }
    return null
  }
}
