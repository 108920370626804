export function setEnquiryModal () {
  const enquiryTypeDropdown = document.getElementById('vjs-enquiry_type-select')
  if (enquiryTypeDropdown) {
    enquiryTypeDropdown.addEventListener('click', () => {
      styleEnquiryModal(enquiryTypeDropdown.value)
    })
  }

  const newEnquiryButtons = Array.from(document.querySelectorAll('.vjs-new-enquiry-button'))
  newEnquiryButtons.forEach(enquiryButton => {
    enquiryButton.addEventListener('click', () => {
      styleEnquiryModal(enquiryButton.dataset.enquiry)
    })
  })
}

export function styleEnquiryModal (selectedEnquiryType) {
  const enquiryTypeDropdown = document.getElementById('vjs-enquiry_type-select')

  if (enquiryTypeDropdown) {
    enquiryTypeDropdown.value = selectedEnquiryType
    const selectOptions = Array.from(enquiryTypeDropdown.getElementsByTagName('option'))
    selectOptions[enquiryTypeDropdown.selectedIndex].click()

    if (selectedEnquiryType === 'delivery_quote_request') {
      document.getElementById('vjs-enquiry-modal_address-fields').style.display = 'block'
      document.getElementById('vjs-enquiry-modal_question-field').style.display = 'none'
    } else {
      document.getElementById('vjs-enquiry-modal_address-fields').style.display = 'none'
      document.getElementById('vjs-enquiry-modal_question-field').style.display = 'block'
    }

    const recaptchaEl = document.getElementById('vjs-g-recaptcha')
    if (!recaptchaEl) return

    if (selectedEnquiryType === 'general' || selectedEnquiryType === 'delivery_quote_request') {
      recaptchaEl.classList.remove('hidden')
    } else {
      recaptchaEl.classList.add('hidden')
    }
  }
}
