import validateTextInput from '../validation_utils/validateTextInput'
import validateSelect from '../validation_utils/validateSelect'

export default function itemDispatchFormValidation () {
  const itemDispatchForm = document.getElementById('vjs-item-dispatch-form')

  const vinteriorDelivery = document.getElementById('vjs-vinterior-delivery')

  if (!itemDispatchForm || !vinteriorDelivery) return

  textInputs(vinteriorDelivery).forEach(inputId => {
    document.getElementById(inputId).addEventListener('blur', e => {
      validateTextInput(e, inputId, setErrorText)
    })
  })

  selectMenus(vinteriorDelivery).forEach(selectId => {
    document.getElementById(selectId).addEventListener('change', e => {
      validateSelect(e, selectId, setErrorText)
    })
  })

  itemDispatchForm.addEventListener('submit', e => {
    validateOnSubmit(e, textInputs(vinteriorDelivery), selectMenus(vinteriorDelivery))
  })
}

function textInputs (vinteriorDelivery) {
  if (vinteriorDelivery.dataset.vinteriorDelivery === 'true') {
    return ['vjs-dispatch-time', 'vjs-tracking-link']
  } else {
    return [
      'vjs-dispatch-time',
      'vjs-courier-input',
      'vjs-tracking-link',
      'vjs-expected-delivery-date'
    ]
  }
}

function selectMenus (vinteriorDelivery) {
  if (vinteriorDelivery.dataset.vinteriorDelivery === 'true') {
    return []
  } else {
    return [
      'vjs-shipment-type-select',
      'vjs-parcel-select',
      'vjs-courier-select'
    ]
  }
}

function validateOnSubmit (e, textInputs, selectMenus) {
  textInputs.forEach(inputId => validateTextInput(e, inputId, setErrorText))
  selectMenus.forEach(selectId => validateTextInput(e, selectId, setErrorText))
}

function setErrorText (validityObject, errorEl) {
  const { valueMissing } = validityObject
  if (valueMissing) errorEl.textContent = 'This field is required'
}
